<template>
  <div class="mister">
      <div class="uk-card uk-card-default uk-card-body ">
          <h1 class="uk-card-title">
              <slot name="pageTitle">{{ translate('sgirot.expert.editTitle') }}</slot>
          </h1>
          <div class="page-content">

              <crud-edit
                      :formProps="{fieldWrapperClass : 'uk-flex uk-flex-stretch uk-flex-wrap uk-gap-m flex-break-large-start'}"
                      :formConfig="formConfig"
                      :itemSource="entityType+'/'+itemId"
                      subject="expert"
                      :subjectId="itemId"
                      :redirect="{name: 'expert'}"
              ></crud-edit>
          </div>
      </div>

  </div>
</template>

<script>

import CrudEdit from '@/client/applications/sgirot/components/crud/Edit.vue';
import {useMeta} from "vue-meta";
import {useI18n} from "vue-i18n/index";
import userFormFields from '@/client/applications/sgirot/forms/simpleUser.js';

export default {

    setup () {
        return  useMeta({
                            title: useI18n().t('sgirot.expert.editTitle')
                        }).meta
    },
    props: {
        itemId : {
            default: null
        }
    },
    middleware: {
        sgirotUser: {
            userTypes: [1]
        }
    },
    data () {
        let formFields = {
            ...userFormFields,
            phone            : {
                name      : 'phone',
                type      : 'text',
                label     : 'sgirot.expert.fields.phone',
                icon      : 'reciever',
                class     : '',
                id        : '',
                validation: {},
                group     : 'group2',
            },
            city             : {
                name          : 'city',
                type          : 'text',
                label         : 'sgirot.expert.fields.city',
                class         : '',
                id            : '',
                icon          : 'home',
                isPrimaryField: true,
                validation    : {},
                group         : 'group2',
            },
            address          : {
                name          : 'address',
                type          : 'text',
                label         : 'sgirot.expert.fields.address',
                class         : '',
                icon          : 'location',
                id            : '',
                isPrimaryField: true,
                validation    : {},
                group         : 'group2',
            },
            commissionPercent: {
                name          : 'commissionPercent',
                type          : 'text',
                icon          : 'info',
                label         : 'sgirot.expert.fields.commissionPercent',
                class         : '',
                id            : '',
                isPrimaryField: true,
                validation    : {
                    required: {},
                    integer : {}
                },
                group         : 'group2',
            },
            enableAdminNotifications: {
                name          : 'enableAdminNotifications',
                type          : 'radio',
                label         : 'sgirot.expert.fields.enableAdminNotifications',
                class         : '',
                id            : '',
                isPrimaryField: true,
                validation    : {
                    required: {},
                    integer : {}
                },
                list: [
                    {value: 0, label: 'core.no'}  ,
                    {value: 1, label: 'core.yes'}
                ],
                group         : 'group2',
            },
        };
        formFields.password.validation = {
            minLength: {
                params: {min: 6}
            }
        }
        formFields.email.validation.liveServerSide = {
            message : 'sgirot.user.emailInUseError',
            params:  {
                'url' : 'expert/email',
                'data': {
                    expertId : this.itemId
                }
            },
        }
        return {
            entityType: 'expert',
            formConfig: {
                fields: formFields,
                groups : {
                    group1: {
                        component: 'FormFieldSet',
                        props    : {
                            title: 'sgirot.expert.fields.userFieldsetTitle',
                            icon : 'user',
                            class: 'uk-width-xlarge uk-margin-top',

                        }
                    },
                    group2: {
                        component: 'FormFieldSet',
                        props    : {
                            title: 'sgirot.expert.fields.expertFieldsetTitle',
                            icon : 'info',
                            class: 'uk-width-xlarge uk-margin-top'
                        }
                    }
                },
                buttons: {}
            }
        }
    },
    components: {CrudEdit},
    layout: 'dashboard',
    methods: {

    }
}
</script>
